/* B O D Y */

body {
    color: #fff;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;

    background-color: #484c53;
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: scroll;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* E L E M E N T S */

button {
    text-align: center;
    font-size: 40px;
    line-height: 60px;
    
    background-color: transparent;
    border: none;
    cursor: hand;
    cursor: pointer;
    width: 60px;
    height: 60px;
}
button svg {
    width: 60px;
    height: 60px;
}
button svg g {
    fill: #0475f1;
}
button:hover svg g {
    fill: #2e3c4b;
}