/* C L A S S E S */

ul.debug {
    list-style-type: none;
    min-width: 160px;

    margin: 0;
    padding: 0;
    position: absolute;
    top: 20px;
    left: 16px;
}
ul.debug li {
    color: #fff;
    font-family: 'Lucida Console', Monaco, monospace;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 18px;

    border: 1px solid #fff;
    border-radius: 6px;

    margin: 0 0 12px 0;
    padding: 6px 9px;
}
ul.debug p {
    margin: 0;
    padding: 0;
}
ul.debug p.title {
    font-weight: bold;
}