/* C L A S S E S */

.editor {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.editor-form, .editor-menu {
    border-radius: 16px;
    width: calc(100vh - 100px);
    min-width: 240px;
    max-width: 320px;
    height: calc(100vh - 100px);
    min-height: 240px;
    max-height: 320px;

    flex-shrink: 0;
    position: relative;
}
.editor-form {
    line-height: 0;

    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.8);

    overflow: hidden;
}
.editor-form textarea {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    white-space: break-spaces;
    word-wrap: break-word;

    background-color: transparent;
    border: none;
    outline: none;
    width: 85%;
    height: auto;
    
    position: absolute;
    resize: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.editor-form textarea::placeholder {
    color: #000;
    opacity: 0.33;
}
.editor-menu {
    border: 1px solid #fff;

    margin: 0 0 0 20px;
    
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* R E S P O N S I V E */

@media (max-width: 700px) {
    .editor-form, .editor-menu {
        width: calc(50vw - 30px);
        height: calc(50vw - 30px);
    }
}

@media (max-width: 540px), (max-height: 480px) {
    .editor {
        top: 80px;
        transform: translate(-50%, 0);
    }
}

@media (min-width: 541px) and (max-height: 480px) {
    .editor {
        padding: 0 0 20px 0;
    }
}

@media (max-width: 540px) {
    .editor {
        min-height: calc(100vh - 80px);

        flex-direction: column;
    }
    .editor-form, .editor-menu {
        width: calc(100vw - 40px);
        min-width: 0;
        max-width: 999px;
        max-height: 999px;
    }
    .editor-form {
        height: calc((100vw - 40px) * 0.8);
        min-height: 0;
    }
    .editor-menu {
        flex: 1;
        margin: 20px 0;
    }
}